@import url('./fontiran.css');


* {
  font-family: IRANSans !important;

}

body {
  direction: rtl;
  background-color: #F9FAFB !important;
  line-height: 1.5;
  box-sizing: border-box;
}


div.MuiChip-colorSuccess {
  background-color: #e6f8e0 !important;
  height: 22px;
  min-width: 22px;
  border-radius: 6px;
}

div.MuiChip-colorError {
  background-color: rgba(255, 72, 66, 0.16) !important;
  height: 22px;
  min-width: 22px;
  border-radius: 6px;
}

div.MuiChip-colorSuccess>span {
  color: #239b17;
  font-weight: 700;
}

div.MuiChip-colorError>span {
  color: #B72136;
  font-weight: 700;
}

div.simplebar-placeholder {
  height: auto !important;
}